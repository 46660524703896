export const SortDescIcon = (props) => {
  const { size, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 24}
      height={size || 24}
      color={color || "#444444"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M20.2827 2H14.2827C14.0066 2 13.7827 2.22386 13.7827 2.5C13.7827 2.77614 14.0066 3 14.2827 3H19.415L13.7827 9C13.4919 9.36064 13.824 10 14.2827 10H20.2827C20.5588 10 20.7827 9.77614 20.7827 9.5C20.7827 9.22386 20.5588 9 20.2827 9H15.1504L20.7828 2.99989C21.0794 2.63216 20.7468 2 20.2827 2Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.7825 17.8891L7.81811 21.8536C7.62285 22.0488 7.30627 22.0488 7.11101 21.8536L3.14655 17.8891C2.95129 17.6939 2.95129 17.3773 3.14655 17.182C3.34182 16.9868 3.6584 16.9868 3.85366 17.182L6.96456 20.2929L6.96456 2.5C6.96456 2.22386 7.18842 2 7.46456 2C7.7407 2 7.96456 2.22386 7.96456 2.5L7.96456 20.2929L11.0754 17.182C11.2707 16.9868 11.5872 16.9868 11.7825 17.182C11.9778 17.3773 11.9778 17.6939 11.7825 17.8891Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2345 22.2514C12.9849 22.1332 12.8784 21.835 12.9966 21.5855L16.8494 13.4518C16.9676 13.2023 17.2658 13.0958 17.5153 13.214C17.7649 13.3322 17.8714 13.6304 17.7532 13.8799L13.9004 22.0136C13.7822 22.2631 13.484 22.3696 13.2345 22.2514Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5153 22.2514C21.7649 22.1332 21.8713 21.835 21.7531 21.5855L17.9003 13.4518C17.7821 13.2023 17.484 13.0958 17.2344 13.214C16.9849 13.3322 16.8784 13.6304 16.9966 13.8799L20.8494 22.0136C20.9676 22.2631 21.2657 22.3696 21.5153 22.2514Z" fill="currentColor" />
      <path d="M14.7826 19H19.7826L20.7826 20H14.2826L14.7826 19Z" fill="currentColor" />
    </svg>
  )
};